import mergeAvailabilityMetadata from './merge-availability-metadata';

const determineAvailabilityMetadata = (month, monthData, numRooms, moment) => {
  const daysInMonth = moment(month).daysInMonth();
  const availableDates = monthData.map(({ date }) => date);

  const monthDayAvailability = Array.from({ length: daysInMonth }).map(
    (_, d) => {
      d = d + 1;
      const dayString = d.toString().padStart(2, '0');
      const previousDayString = (d - 1).toString().padStart(2, '0');

      const { minimumPrice, availabilityForRooms = [] } =
        monthData.find(({ date }) => date === dayString) || {};

      const { availabilityForRooms: availabilityForRoomsPreviousDay = [] } =
        monthData.find(({ date }) => date === previousDayString) || {};

      const availableForAllRooms = numRooms === availabilityForRooms.length;
      const availableForAllRoomsPreviousDay =
        numRooms === availabilityForRoomsPreviousDay.length;

      const metadata = mergeAvailabilityMetadata(availabilityForRooms);

      const isCheckIn =
        !!availableDates?.includes(dayString) &&
        availableForAllRooms &&
        !metadata.NoArrive;

      const isCheckOut =
        !!availableDates?.includes(previousDayString) &&
        availableForAllRoomsPreviousDay;

      const dayData = {
        date: `${month}-${dayString}`,
        isCheckIn,
        isCheckOut,
        metadata,
      };

      if (minimumPrice && availableForAllRooms) {
        dayData.minimumPrice = minimumPrice;
      }

      return dayData;
    }
  );

  return monthDayAvailability;
};

export default determineAvailabilityMetadata;
